<template>
  <div id="salary-box" class="salary">
    <!--    <div class="time">-->
    <!--      <p @click="selectTime">{{time}} &nbsp;<i class="iconfont icon f14"-->
    <!--        :class="{'icon-xiala':!showSelectTime,'icon-shangla':showSelectTime}"-->
    <!--      ></i></p>-->
    <!--    </div>-->
    <div class="top">
      <div class="time">
        <p @click="selectTime">
          {{ time }} &nbsp;<i
            class="iconfont icon f14"
            :class="{
              'icon-xiala': !showSelectTime,
              'icon-shangla': showSelectTime,
            }"
          ></i>
        </p>
      </div>
      <span class="look-all" @click="lookAll">查看全部</span>
    </div>
    <div class="top-over"></div>
    <div v-if="salaryList.length > 0">
      <div
        class="salary-collect"
        v-for="(item, index) in salaryList"
        :key="index"
      >
        <ul @click="lookInfo(item)">
          <li v-if="item.type === 0">{{ item.yearMonth }}工资条</li>
          <li v-if="item.type === 1">{{ item.year }}年终奖金工资条</li>
          <li v-if="item.type === 2">{{ item.year }}年度调薪工资条</li>
          <li class="f14 color">
            查看详情&nbsp;<i class="iconfont icon-youjiantou f14 color"></i>
          </li>
        </ul>
        <p class="amount">{{ item.realPaySalaryStr }}<span class="unit">元</span></p>
        <span v-if="item.type === 0" class="f14 color">本月共发放薪资</span>
        <span v-if="item.type === 1" class="f14 color">年终奖金共发放薪资</span>
        <span v-if="item.type === 2" class="f14 color">调薪后税前工资</span>
      </div>
      <div class="loading_wrapper" v-if="salaryList.length >= 3">
        <span>已经到底啦...</span>
      </div>
    </div>

    <van-empty description="暂无数据" v-if="salaryList.length === 0" />
    <!--时间-->
    <select-time
      @confirmTime="confirmTime"
      :hide.sync="showSelectTime"
    ></select-time>
    <!--加载-->
    <loading-temp :loading="loading" />
  </div>
</template>

<script>
import selectTime from "../../components/selectTime";
import loadingTemp from "../../components/loading";
import { fetchSalaryList } from "../../server/user";
import { watermark } from "../../unit/units";
import { mapState } from "vuex";
export default {
  name: "Salary",
  data() {
    return {
      salaryList: [], // 工资条列表
      loading: false,
      showSelectTime: false,
      time: "请选择日期",
      time_one: "2021年9月",
      minDate: "",
      maxDate: "",
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    setTimeout(() => {
      // 创建水印
      this.$nextTick(() => {
        watermark.setWatermark({
          id: "salary-box",
          userName: this.userInfo.nick_name,
        });
      });
    }, 10);
  },
  components: {
    selectTime,
    loadingTemp,
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    /**
     * 选择时间
     */
    selectTime() {
      this.showSelectTime = !this.showSelectTime;
    },
    /**
     *
     */
    initData(periodStart = "", periodEnd = "") {
      this.loading = true;
      let sendData = {
        periodStart,
        periodEnd,
      };
      fetchSalaryList(sendData).then((res) => {
        if (res.success) {
          this.loading = false;
          this.salaryList = res.data;
        }
      });
    },
    /**
     * 确定当前时间
     */
    confirmTime(val) {
      let month =
        val.getMonth() + 1 < 10
          ? "0" + (val.getMonth() + 1)
          : val.getMonth() + 1;
      let time = val.getFullYear() + "-" + month;
      this.time = val.getFullYear() + "年" + month;
      this.time_one = this.time;
      this.initData(time, time);
    },
    lookInfo(row) {
      this.loading = true;
      if (row.type === 0) {
        this.$router.push({
          path: `/particulars/${row.year}/${row.month}`,
        });
      } else if (row.type === 1) {
        this.$router.push({
          path: `/yearEndBonus/${row.id}`,
           query: {
            year:row.year,
          },
        });
      } else if (row.type === 2) {
        this.$router.push({
          path: `/salaryAdjustment/${row.id}`,
          query: {
            year:row.year,
          },
        });
      }
    },
    /**
     * 查看全部
     */
    lookAll() {
      this.initData();
    },
  },
};
</script>

<style scoped lang="scss">
.salary {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: linear-gradient(#487ee2, #487ee2, rgb(243, 243, 248));
  padding-bottom: 20px;
}

.top-over {
  width: 100%;
  height: 60px;
}

.top {
  position: fixed;
  height: 60px;
  background: #4d82e1;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .time {
    width: 150px;
    height: 30px;
    background-color: #a2beef;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    margin: 0 auto;
    border-radius: 10px;
    color: #fff;
  }

  .look-all {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
  }
}

.salary-collect {
  width: 340px;
  height: 150px;
  overflow: hidden;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 14px;
  padding-top: 15px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;

  ul {
    display: flex;
    font-size: 18px;
    width: 320px;
    height: 36px;
    line-height: 35px;
    justify-content: space-between;
    margin: 0 auto;
    border-bottom: 1px dashed #787878;
  }
}

.amount {
  font-size: 40px;
  color: #4d82e1;
  margin-top: 15px;
  margin-bottom: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  .unit {
    font-size: 38px;
    margin-left: 2px;
    color: inherit;
  }
}

.loading_wrapper {
  text-align: center;

  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>
