<template>
  <div class="overlay" v-if="hide">
    <div class="container">
      <van-datetime-picker
          :type="dataType"
          @cancel="close"
          @confirm="confirmTime"
          :title="title"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "selectTime",
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: '选择年月'
    },
    hide: {
      type: Boolean,
      default: false,
    },
    year: {
      type: [String, Number],
      default: () => {
        return ''
      }
    },
    /**
     * 选择时间类型
     */
    dataType: {
      type: String,
      default: 'year-month'
    },
    // let nowTime = new Date()
    // let nowYear = nowTime.getFullYear()
    // let month = nowTime.getMonth()
    // this.minDate = new Date(nowYear, 0, 1)
    // this.maxDate = new Date(nowYear, month-1, 1)
    /**
     * 最小时间
     */
    minDate: {
      type: [Object, Date],
      default: () => {
        return new Date(new Date().getFullYear(),0,1)
      }
    },
    /**
     * 最大时间
     */
    maxDate: {
      type: [Object, Date],
      default: () => {
        let nowTime = new Date()
        let nowYear = nowTime.getFullYear()
        let month = nowTime.getMonth()
        return new Date(nowYear,month-1,1)
      }
    }
  },
  methods: {
    /**
     *
     * @param val 选种当前时间date类型
     */
    confirmTime (val) {
      this.$emit('confirmTime', val)
      this.close()
    },
    /**
     * 关闭
     */
    close () {
      this.$emit('update:hide', false)
    }

  }
}
</script>

<style scoped lang="scss">
.overlay {
  height: 100vh;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100vw;
  background: rgba(0, 0, 0, .5);
  .container {
    position: absolute;
    bottom: -200px;
    width: 100vw;
    animation:moveTop .3s linear forwards ;
  }
}
@keyframes moveTop {
   0%{
     bottom: -200px;
   }
  100%{
    bottom: 0;
  }
}

</style>
